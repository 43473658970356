globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5a80c60ebf04c4d01aa639c9542416dc938fb791"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  dsn: 'https://4aaefa69d779fbad02f0877fae3af24e@o4505172191608832.ingest.us.sentry.io/4506871856168960',

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  enabled: process.env.NEXT_PUBLIC_USE_SENTRY == 'yes',

  // Add optional integrations for additional features
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
    }),
  ],

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
});
